.page-title {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 1em;
}
.parcel-event-log {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.systemLog-row {
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.chip {
  border: 1px solid #000;
  padding: 4px;
  border-radius: 10px;
  width: fit-content;
  font-size: 10px;
  font-weight: 600;
}

.chip.error {
  border-color: rgb(189, 3, 3);
  color: rgb(189, 3, 3);
  background-color: rgba(189, 3, 3, 0.1);
}
.chip.ERROR {
  border-color: rgb(189, 3, 3);
  color: rgb(189, 3, 3);
  background-color: rgba(189, 3, 3, 0.1);
}
.chip.success {
  border-color: rgb(0, 141, 19);
  color: rgb(0, 141, 19);
  background-color: rgba(0, 141, 19, 0.1);
}
.chip.DONE {
  border-color: rgb(0, 141, 19);
  color: rgb(0, 141, 19);
  background-color: rgba(0, 141, 19, 0.1);
}

.order-update-result {
  width: 33%;
  border: 1px solid #ccc;
  border-radius: 20px;
  align-items: center;
  margin-right: 10px;
  padding: 10px;
}
.order-update-result.order {
  border-color: #3250a8;
  background-color: #3250a810;
}
.order-update-result.parcel {
  border-color: #32a859;
  background-color: #32a85910;
}
.update-status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}
.update-status-label {
  font-size: 10px;
}

/** Parcel page **/
.parcel-status {
  width: fit-content;
  border-radius: 7px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea546f;
  color: #fff;
  font-weight: 600;
}

.keeper-status {
  width: fit-content;
  border-radius: 7px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea546f;
  color: #fff;
  font-weight: 600;
  font-size: 0.8em;
}

.parcel-details {
  background-color: rgb(231, 231, 231);
}

.partners {
  display: flex;
  flex-direction: column;
  height: 50px;
}
.partners > .partner {
  border-radius: 10px;
  padding: 5px;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}
.partners > .partner > .type {
  font-size: 0.8em;
  color: #a7a7a7;
  margin-bottom: 4px;
}
.partners > .partner > .name {
  font-size: 1em;
}
.partners > .partner > .status {
  margin-top: 5px;
  border: 1px solid #000;
  padding: 4px;
  border-radius: 10px;
  width: fit-content;
  font-size: 9px;
  font-weight: 600;
  cursor: pointer;
  z-index: 2;
}
.partners > .partner > .status:hover {
  margin-top: 5px;
  border: 1px solid rgb(110, 110, 110);
  color: rgb(110, 110, 110);
  padding: 4px;
  border-radius: 10px;
  width: fit-content;
  font-size: 9px;
  font-weight: 600;
}
.parcel-content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
}
.parcel-content > .actions-buttons {
  position: absolute;
  top: -17px;
  right: 20px;
}
.parcel-content-events {
  flex: 1;
  padding-top: 0px;
}
.history-events > .subtitle {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 1em;
}

/*** AVAILABILITIES **/
.search-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
}
.search-bar > .search-button {
  height: fit-content;
  align-self: flex-end;
}

.filter-bar {
  margin-bottom: 2em;
}

.counted-slots {
  display: flex;
  flex-direction: row;
}
.counted-slots > .slot {
  margin-right: 1em;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 160px;
  border: 2px solid #f0f0f0;
  border-radius: 25px;
  align-items: center;
  background-color: white;
  justify-content: center;
}
.counted-slots > .slot > .total {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 20px;
}
.counted-slots > .slot > .label {
  font-size: 1em;
  color: #5f5f5f;
}

/** Dashboard Ops **/
.parcels-with-problems {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.parcels-with-problems > .parcels-column {
  width: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.parcels-column > .parcels-column-header {
  margin-bottom: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 10px;
}
.parcels-column > .parcels-column-content {
  flex: 1;
}
.parcel-line {
  height: 30px;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.parcel-line:hover {
  background-color: #f0f0f0;
}

.map-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}
.map-total-elements {
  font-weight: 600;
}

.map-legend {
  margin-bottom: 0.5em;
  display: flex;
}
.map-legend-item {
  font-size: 0.9em;
  margin-right: 10px;
}
.stat-title {
  font-size: 0.9em;
  color: rgb(136, 136, 136);
}
.stat-content {
  font-size: 2em;
  font-weight: 600;
}

.popup-link {
  margin-top: 10px;
  float: right;
  font-size: 0.7em;
  text-transform: uppercase;
}
.popup-link:hover {
  font-weight: 600;
}

/*** Tabs menu ***/
.tabs-menu {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.tab-menu {
  padding: 10px;
  color: #909090;
  cursor: pointer;
}
.tab-menu.active {
  font-weight: 600;
  color: #000;
  border-bottom: 1px solid #000;
}

/*** KEEPER ***/
.keeper-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  padding: 40px;
  flex-direction: row;
  min-height: 200px;
  border-bottom: 1px solid #f1f1f1;
}
.keeper-infos {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.keeper-infos > .title {
  font-size: 3em;
  font-weight: 600;
}
.keeper-infos > .order {
  margin-top: 3em;
  margin-bottom: 1em;
}

.keeper-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background-color: #f9f9f9;
}
.keeper-content > .actions-buttons {
  position: absolute;
  top: -17px;
  right: 20px;
}
.no-content {
  margin-top: 1em;
  margin-bottom: 1em;
}

.menu-icon {
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -17px;
  font-size: 1.5rem;
  opacity: 1;
  color: #253553;
  transition: color 300ms;
}

.select__control--is-disabled {
  background-color: #e5e6e5 !important;
}
